import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/karrabing'

import background from '../../assets/images/karrabing/background.png'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
