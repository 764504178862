import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/karrabing/background.png'
import still from '../../../assets/images/karrabing/still.png'


import img1 from '../../../assets/images/karrabing/1.jpg' 
import img2 from '../../../assets/images/karrabing/2.jpg' 
import img3 from '../../../assets/images/karrabing/3.jpg' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'Aboriginal Land Rights protest, Brisbane, 1982. Via www.deadlystory.com',
    image: img1
  },
  {
    label: 'Sol Bellear at Aboriginal Land Rights protest, Brisbane, 1982. Via www.abc.net.au',
    image: img2
  },
  {
    label: 'Aboriginal Tent Embassy protest, Parliament House in Canberra, 1972. Via  www.nma.gov.au',
    image: img3
  },
];

export default function Album(props) {

  return (
    <>
      <Header
        name='Karrabing Film Collective'
        title='The Mermaids, or Aiden in Wonderland'
        date='25 October - 21 November 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
The Mermaids, or Aiden in Wonderland is a surreal exploration of Western toxic contamination, capitalism, and human and non-human life. Set in a land and seascape poisoned by capitalism where only Aboriginals can survive long periods outdoors, the film tells the story of a young Indigenous man, Aiden, taken away when he was just a baby to be a part of a medical experiment to save the white race. He is then released back into the world to his family. As Aiden, a stranger to his own land is reinserted into existence; he becomes acquainted with territorial nature and folklore – mermaids, a bee, a cockatoo bringing forth a pertinent inquiry of whose and which lives matter.
        </Typography>
      </Text>

      <Film 
        url="638415468?h=daa98056e0"
        title="Karrabing Film Collective, The Mermaids, or Aiden in Wonderland (2017) 26 min."
        titleShort="Karrabing - The Mermaids"
      />

      <Essay 
        title="Elizabeth Povinelli"
        subtitle="The Normativity of the Creeks"
       url="/articles/elizabeth-povinelli-the-normavity-of-the-creeks"
      >
      <Typography
        variant='h6'
      >
All Or Nothing
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
There is a coastal tidal creek in Northern Australia where a young girl lies facedown. Called Tjipel in the language of the area, she came to this creek as a beautiful teenager who decided to dress as a young man, equipping herself with male clothes and hunting implements, including a spear and spear thrower. As she travelled down the coast, she did various things, including spearing a wallaby. But the heart of her story concerns an encounter she had with an old man. As she passed between two coastal points, a bird told her that an old man was approaching. And so she lay belly down in the sand to hide what parts of her body would reveal—that she was in fact an adolescent female. The old man, thinking she was a young man, insisted that (s)he get up and cook the wallaby. She put him off, claiming to be sick.
      </Typography>
      </Essay>

      <Soundtrack 
        title="Geontopowers"
        subtitle="Elisabeth Povinelli in conversation with Felice Moramarco"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/elisabeth-povinelli-in-conversation-with-felice-moramarco-podcast?si=fde99d0dd1a2467aaf4ee6e4080834d6"
      />

      <Gallery 
        slides={slides}
        title=''
      />
      
      <Footer />

    </>
  );
}
